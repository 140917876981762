import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useLoginErrorContext } from "./useLoginErrorContext";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
    //States
    const [error, setError] = useState(null);

    //Hooks
    const { dispatch } = useAuthContext();
    const { dispatch: dispatchLoginError } = useLoginErrorContext();
    const navigate = useNavigate();

    const login = async (formData) => {
        setError(null);

        const xhr = new XMLHttpRequest();
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_BACKEND_URL;

            xhr.open('POST', url, true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        try {
                            const data = JSON.parse(xhr.responseText);

                            if (data.status === "success") {
                                // Save the user to local storage
                                localStorage.setItem('user', JSON.stringify(data));

                                // Update the auth context
                                dispatch({ type: 'LOGIN', payload: data });

                                navigate("/main/");
                            } else if (data.status === "error") {
                                dispatchLoginError({ type: 'SET_LOGIN_ERROR', payload: data.message });
                            };
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                            reject(error);
                        }
                    } else {
                        console.error('HTTP error! Status:', xhr.status);
                        reject(xhr.status);
                    }
                }
            };

            xhr.send(formData);
        });
    };

    return { login, error }
};
import { LoginErrorContext } from "../context/LoginErrorContext";
import { useContext } from "react";

export const useLoginErrorContext = () => {
    const context = useContext(LoginErrorContext);

    if (!context) {
        return Error('useLoginErrorContext must be used inside an LoginErrorContextProvider');
    };

    return context;
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/scss/main.scss"

//Providers
import { UsersContextProvider } from 'context/UsersContext';
import { AuthContextProvider } from './context/AuthContext';
import { TokenErrorContextProvider } from 'context/TokenErrorContext';
import { LoginErrorContextProvider } from 'context/LoginErrorContext';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <LoginErrorContextProvider>
      <AuthContextProvider>
        <TokenErrorContextProvider>
          <UsersContextProvider>
            <Router>
              <App />
            </Router>
          </UsersContextProvider>
        </TokenErrorContextProvider>
      </AuthContextProvider>
    </LoginErrorContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


import React from "react";

function Header(props) {

    const {
        id,
        photoName,
        name1,
        onClickEdit
    } = props;

    const appendThumbToFileName = (fileName) => {
        const dotIndex = fileName.lastIndexOf(".");
        if (dotIndex !== -1) {
            const newFileName = fileName.slice(0, dotIndex) + "_thumb" + fileName.slice(dotIndex);
            return newFileName;
        } else {
            console.log("Dosya uzantısı bulunamadı.");
            return null;
        };
    };

    return (
        <div className="card customSliders">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="lesson_name">
                        <h6 className="mb-0 fw-bold fs-6 mb-4">{name1}</h6>
                        <div className="project-block">
                            <img
                                src={process.env.PUBLIC_URL + `/uploads/banners/${appendThumbToFileName(photoName)}`}
                                style={{
                                    width: "90%",
                                    borderRadius: "8px",
                                    objectFit: "cover"
                                }}
                            />
                        </div>
                    </div>
                    <div className="btn-group-vertical" role="group" aria-label="Basic outlined example">
                        <button type="button" className="btn btn-outline-secondary" onClick={onClickEdit}>
                            <i className="icofont-edit text-success"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Header;
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Logo from "../../assets/images/logos/Logo.svg";
import NovusIDLogo from "../../assets/images/logos/NovusIDLogo1.svg";
import { useLogin } from "../../hooks/useLogin";
import { useTokenErrorContext } from "hooks/useTokenErrorContext";
import { useLoginErrorContext } from "hooks/useLoginErrorContext";

function SignIn() {

    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const { login, error } = useLogin();
    const { tokenError, dispatch } = useTokenErrorContext();
    const { loginError, dispatch: dispatchLoginError } = useLoginErrorContext();

    const handleChange = (e) => {
        dispatchLoginError({ type: 'DELETE_LOGIN_ERROR' });
        setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('module', 'users');
        formData.append('action', 'login');
        formData.append('email', credentials.email);
        formData.append('password', credentials.password);

        await login(formData);
    };

    return (
        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
            <div className="w-100 p-3 p-sm-5 card border-0 bg-dark text-light" style={{ maxWidth: "32rem" }}>
                <div className="row g-1 p-3 p-sm-4">
                    <div className="col-12 text-center mb-1 mb-lg-5 singInLogo">
                        <img src={Logo} alt="" width={"100%"} />
                    </div>
                    <div className="col-12 text-center mb-1 mb-lg-5">
                        <h1>Giriş Yap</h1>
                        <span>Admin Panel</span>
                    </div>
                    <div className="col-12">
                        <div className="mb-2">
                            <label className="form-label">E-Posta</label>
                            {loginError === "Kullanıcı Bulunamadı" &&
                                <label className="alert alert-danger" style={{ float: "right", padding: 3, marginTop: "-0.5rem", marginBottom: 0 }}>{loginError}</label>
                            }
                            <input
                                id="email"
                                type="email"
                                className="form-control form-control-lg"
                                style={{ borderColor: `${loginError === "Kullanıcı Bulunamadı" ? "red" : "var(--border-color)"}` }}
                                placeholder={"ornek@mail.com"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-2">
                            <label className="form-label">Parola</label>
                            {loginError === "Parola Doğrulanamadı" &&
                                <label className="alert alert-danger" style={{ float: "right", padding: 3, marginTop: "-0.5rem", marginBottom: 0 }}>{loginError}</label>
                            }
                            <input
                                id="password"
                                type="password"
                                className="form-control form-control-lg"
                                style={{ borderColor: `${loginError === "Parola Doğrulanamadı" ? "red" : "var(--border-color)"}` }}
                                placeholder="***************"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <button
                            className="btn btn-lg btn-block btn-light lift text-uppercase"
                            alt="signin"
                            type="submit"
                            onClick={handleClick}
                        >
                            GİRİŞ
                        </button>
                        {error &&
                            <h6 className="d-flex justify-content-center align-items-center">
                                <div className="alert alert-danger w-75 mt-4">{error}</div>
                            </h6>
                        }
                    </div>
                    <div className="col-12 text-center mt-5">
                        <img src={NovusIDLogo} alt="" width={"40%"} />
                    </div>
                </div>
            </div>
            <Modal
                show={tokenError}
                onHide={() => {
                    dispatch({ type: 'DELETE_TOKEN_ERROR' });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Oturumunuzun süresi doldu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        Lütfen tekrar giriş yapınız.
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default SignIn;
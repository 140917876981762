import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import Banner from "./Banner";
import { useXhr } from "hooks/useXhr";
import { Button, Spinner } from "react-bootstrap";

function Banners() {
    //States
    const [banners, setBanners] = useState(null);
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()

    //Variables
    const requiredFields = ['name1'];
    const maxWidth = 750;
    const maxMb = 1;

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const maxSizeInBytes = maxMb * 1024 * 1024;

        if (file.size > maxSizeInBytes) {
            setError({
                field: "photo",
                note: `Dosya boyutu ${maxMb}MB'ı geçemez.`
            });
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = function () {

            if (img.width > maxWidth) {
                setError({
                    field: "photo",
                    note: `Görsel genişliği ${maxWidth} sınırını aşmamalıdır.`
                });
                return;
            }

            const newFileName = generateUniqueFileName(file);
            const newFile = new File([file], newFileName, { type: file.type });

            setIsUpdate(prevState => ({
                ...prevState,
                [id]: newFile
            }));

            setError({
                field: null,
                note: null
            });
        };

        img.onerror = function () {
            setError({
                field: "photo",
                note: "Görsel yüklenemedi, lütfen geçerli bir dosya seçin."
            });
        };
    };


    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('name1', isUpdate.name1);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
        };
    };

    useEffect(() => {
        getBanners();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Bannerlar" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    <div className="row g-3 gy-5 py-3 row-deck">
                        {banners &&
                            banners.map((d, i) => {
                                return <div
                                    key={"ljsdhl" + i}
                                    className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                                    style={{marginTop: "30px"}}
                                >
                                    <Banner
                                        id={d.id}
                                        photoName={d.photoName}
                                        name1={d.name1}
                                        onClickEdit={() => setIsUpdate(d)}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={isUpdate}
                onHide={() => {
                    setIsUpdate(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Banner Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="name1" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name1"
                            value={isUpdate && isUpdate.name1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "name1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                            style={{ borderColor: `${error.field === "photo" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "photo" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="form-label" style={{ color: "red" }}>
                        {`Bu alanın geçerli görsel genişliği: ${maxWidth}px`}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Banners;